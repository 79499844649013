<template>
  <div id="homeCarouselFeaturedBrands" class="carousel slide">
    <b-carousel id="carousel-1" :interval="3000" controls indicators img-width="1024" img-height="580"
      style="text-shadow: 1px 1px 2px #333">
      <!-- Text slides with image -->
      <b-carousel-slide v-for="slide in slides" v-lazy :key="slide.id" :caption="slide.title" :text="slide.description"
        :img-src="slide.img" alt="Slide Image" img-width="1024" img-height="580">
        <template #img>
          <img class="d-block img-fluid w-100" width="1024" height="580" v-bind:src="slide.img" :alt="slide.title" />
        </template></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

export default {
  name: "HomeSlideshow",
  data() {
    return {
      slides: [
        // {
        //   img: "/img/featured brands.jpg",
        //   title: "",
        //   description: "",
        // },
        {
          id: 1,
          img: "/img/ph-colcom.jpg",
          title: "",
          description: "",
        },
        {
          id: 2,
          img: "/img/butcher m.jpg",
          title: "",
          description: "",
        },

        {
          id: 3,
          img: "/img/ph-greenwood.jpg",
          title: "",
          description: "",
        },

        {
          id: 4,
          img: "/img/ph-brands.jpg",
          title: "",
          description: "",
        },

      ],
    };
  },
};
</script>

<style scoped>
.slider-container {
  margin-top: 200px;
}

.carousel-slide-item {
  background-color: #0b230cb5;
  padding: 20px;
  color: white;
  position: absolute;
  top: 0;
  bottom: 200px;
  width: 100%;
  height: 100%;
  /* left: ; change it to 50%, if you want the text only shows in the half part */
  right: 0;
}

@media screen and (max-width: 575px) {
  .carousel-item {
    height: 210px;
  }
}

@media screen and (max-width: 470px) {
  .carousel-item {
    height: 190px;
  }
}

@media screen and (max-width: 575px) {
  .carousel-mobile-image {
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: 992px) {
  .slider-image {
    height: 600px !important;
  }
}

@media (max-width: 992px) {
  .slider-image {
    height: 600px !important;
  }
}

.slider-image {
  height: 600px !important;
}

@media (max-width: 600px) {
  .slider-image {
    height: 100% !important;
  }
}
</style>
